// import Vue from "vue";
// Import Vue
// import VueViewer from "vue-viewer";
// Import the Vue Viewer

// Use VueViewer as a global component
// Vue.use(VueViewer);

document.addEventListener("DOMContentLoaded", function () {
  // Vue instance for Projects List
  const projectsContainer = document.getElementById("projects-container");
  if (projectsContainer) {
    new Vue({
      el: "#projects-container",
      data() {
        return {
          projects: [],
          categories: [],
          jobTypes: ["Electrical Installation", "Maintenance", "Repairs"], // Define job types
          loading: true,
        };
      },
      mounted() {
        this.loadData("project", "-1");
        this.loadCategories(); // Load categories for filtering
      },
      methods: {
        loadData(postType, postLimit) {
          fetch(
            `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}&limit=${postLimit}`
          )
            .then((response) => response.json())
            .then((data) => {
              this.projects = data;
              this.loading = false;
            });
        },
        loadCategories() {
          fetch(`/wp-admin/admin-ajax.php?action=map_get_categories`)
            .then((response) => response.json())
            .then((data) => {
              this.categories = data;
            });
        },
      },
      components: {
        "project-list": () =>
          import(
            /* webpackChunkName: "project-list" */ "./vue-components/ProjectList.vue"
          ),
      },
      template: `
        <div>
          <div v-if="loading">Loading...</div>
          <div v-else>
            <project-list
              :projects="projects"
              :categories="categories"
              :job-types="jobTypes"
            ></project-list>
               

          </div>
        </div>
      `,
    });
  }

  const singleProjectContainer = document.getElementById(
    "single-project-container"
  );

  if (singleProjectContainer) {
    new Vue({
      el: "#single-project-container",
      data() {
        return {
          project: [], // Keep this an array to store the project data
          loading: true,
          relatedLoading: true, // Separate loading state for related posts
          currentPostId: myGlobalData.currentPostId || null, // Access the current post ID
          breadcrumbs: myGlobalData.breadcrumbs, // Use the breadcrumbs from global data
          relatedProjects: [], // Add this line to your data structure
        };
      },
      mounted() {
        this.loadProjectData("project", 0, this.currentPostId);
        console.log("Viewer reference during mount:", this.$refs.viewer);
        this.$refs.viewer && this.$refs.viewer.init();
      },
      methods: {
        loadProjectData(postType, postLimit, postID) {
          fetch(
            `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}&limit=${postLimit}&post_id=${postID}`
          )
            .then((response) => response.json())
            .then((data) => {
              this.project = data;
              this.loading = false;

              // Check if the location_map exists and has latitude and longitude
              const locationMap = this.project[0]?.location_map; // Optional chaining
              if (locationMap?.lat && locationMap?.lng) {
                console.log("init map fired");
                this.initMap(locationMap.lat, locationMap.lng); // Init map here
              } else {
                console.log("map init failed: location data is not available");
              }

              console.log("related posts", this.project[0].relevant_posts);

              if (this.project[0]?.relevant_posts) {
                this.fetchRelated(this.project[0].relevant_posts);
              }
            });
        },
        initMap(lat, lng) {
          // Access the map element using getElementById
          setTimeout(() => {
            const mapElement = document.getElementById("map");
            if (mapElement) {
              const map = new google.maps.Map(mapElement, {
                center: { lat: parseFloat(lat), lng: parseFloat(lng) },
                zoom: 12,
              });

              new google.maps.Marker({
                position: { lat: parseFloat(lat), lng: parseFloat(lng) },
                map: map,
              });
            } else {
              console.log("map element doesn't exist after timeout");
            }
          }, 100); // Adjust the delay as needed
        },
        fetchRelated(ids) {
          console.log("fetch related ids ===", ids);
          fetch(
            `/wp-admin/admin-ajax.php?action=map_get_related_posts&post_ids=${ids}`
          )
            .then((response) => response.json())
            .then((data) => {
              this.relatedProjects = data;
              this.relatedLoading = false; // Related posts are done loading

              console.log("related posts data returned", data);
              // console.log("related projects: ", this.relatedProjects);
              // Check if the location_map exists and has latitude and longitude
              // console.log("related posts", this.project[0].relevant_posts);
            });
        },
        openLightbox(image) {
          console.log("Attempting to open lightbox with image:", image);
          console.log("Viewer reference:", this.$refs.viewer);

          if (this.$refs.viewer) {
            try {
              // If using a different method for VueViewer, check its documentation
              if (typeof this.$refs.viewer.show === "function") {
                this.$refs.viewer.show([image]);
              } else {
                console.error(
                  "The show method is not available on viewer reference."
                );
              }
            } catch (error) {
              console.error("Error opening lightbox:", error);
            }
          } else {
            console.error("Lightbox reference is undefined");
          }
        },
        openLightboxMethod() {
          // Logic to open the lightbox goes here
          console.log("Lightbox opened!");
        },
      },
      watch: {
        project: {
          immediate: false, // No need to trigger on initial load
          handler(project) {
            // This can be used for future updates to project
            if (project.length > 0) {
              const locationMap = project[0]?.location_map; // Optional chaining
              if (locationMap?.lat && locationMap?.lng) {
                console.log("Project updated, init map fired");
                this.initMap(locationMap.lat, locationMap.lng); // Initialise the map when project data is updated
              } else {
                console.log("map init failed: location data is not available");
              }
            }
          },
        },
      },
      components: {
        "single-project": () =>
          import(
            /* webpackChunkName: "project-single" */ "./vue-components/ProjectSingle.vue"
          ),
      },
      template: `
        <div>
        <div v-if="loading" class="loader">
      <img src="./wp-content/uploads/2024/09/logo-icon-only-1.png" alt="Client Logo" class="loader-logo" />
      <div class="loader-text">Loading...</div>
    </div>
          <div v-else>
         
            <single-project :project="project" :breadcrumbs="breadcrumbs" :relatedProjects="relatedProjects" @open-lightbox="openLightbox"> </single-project>
                <vue-viewer ref="viewer"></vue-viewer>

          </div>


           <div v-if="relatedLoading" class="related-loader">Loading related posts...</div>
        <div v-else>
        <div class="blog-header">        <h2>Related Posts</h2>
</div>
        <div class="blog-related-wrapped">
          <div v-for="blogitem in relatedProjects" :key="blogitem.id" class="blog-item">
          <div class="img-wrapper-blogitem" v-html="blogitem.featured_img"></div>
            <h2 v-html="blogitem.title">{{ blogitem.title }}</h2>
            <div><p v-html="blogitem.excerpt">{{ blogitem.excerpt}}</p></div>
            <p>ID: {{ blogitem.id }}</p>
            <a :href="blogitem.permalink">Read more</a>
          </div>
        </div>
        </div>
        </div>
      `,
    });
  }
});

// // Vue instance for Blogs
// new Vue({
//   el: "#blogs-container",
//   data() {
//     return {
//       blogs: [],
//       loading: true,
//     };
//   },
//   mounted() {
//     this.loadData("blog");
//   },
//   methods: {
//     loadData(postType) {
//       fetch(
//         `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           this.blogs = data;
//           this.loading = false;
//         });
//     },
//   },
//   components: {
//       'blog-list': () => import(/* webpackChunkName: "blog-list" */ './vue-components/BlogList.vue'),
//   },
//   template: `
//     <div>
//       <div v-if="loading">Loading...</div>
//       <div v-else>
//         <blog-list :blogs="blogs"></blog-list>
//       </div>
//     </div>
//   `,
// });

// new Vue({
//   el: "#single-project-container",
//   data() {
//     return {
//       project: [],
//       loading: true,
//     };
//   },
//   mounted() {
//     this.loadProjectData("project", 1);
//     this.projects.forEach((project) => {
//       if (project.location_map) {
//         this.initMap(project.location_map.lat, project.location_map.lng);
//       }
//     });
//   },
//   methods: {
//     loadProjectData(postType, postLimit) {
//       fetch(
//         `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}&limit=${postLimit}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           this.project = data;
//           console.log("This is projects data:", data);
//           this.loading = false;
//         });
//     },
//  // Example: Use the Google Maps JavaScript API to render the map
//  this.projects.forEach((project) => {
//   if (project.location_map) {
//     const mapElement = document.getElementById('map');
//     const lat = mapElement.dataset.lat;
//     const lng = mapElement.dataset.lng;

//     const map = new google.maps.Map(mapElement, {
//       center: { lat: parseFloat(lat), lng: parseFloat(lng) },
//       zoom: 12,
//     });

//     const marker = new google.maps.Marker({
//       position: { lat: parseFloat(lat), lng: parseFloat(lng) },
//       map: map,
//     });
//   }
// }),
//   },
//   components: {
//     "single-project": () =>
//       import(
//         /* webpackChunkName: "project-single" */ "./vue-components/ProjectSingle.vue"
//       ),
//   },
//   template: `
//       <div>
//         <div v-if="loading">Loading...</div>
//         <div v-else>
//         <h2>TEST</h2>
//         <single-project :project="project"> </single-project>
//         </div>
//       </div>
//     `,
// });

// Import and use Vue Viewer

// document.addEventListener("DOMContentLoaded", function () {
//   // Vue instance for Projects
//   new Vue({
//     el: "#projects-container",
//     data() {
//       return {
//         projects: [],
//         categories: [],
//         jobTypes: ["Electrical Installation", "Maintenance", "Repairs"], // Define job types
//         loading: true,
//       };
//     },
//     mounted() {
//       this.loadData("project", "-1");
//       this.loadCategories(); // Load categories for filtering
//     },
//     methods: {
//       loadData(postType, postLimit) {
//         fetch(
//           `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}&limit=${postLimit}`
//         )
//           .then((response) => response.json())
//           .then((data) => {
//             this.projects = data;
//             console.log("This.projects data:", data);
//             this.loading = false;
//           });
//       },
//       loadCategories() {
//         fetch(`/wp-admin/admin-ajax.php?action=map_get_categories`)
//           .then((response) => response.json())
//           .then((data) => {
//             this.categories = data;
//           });
//       },
//     },
//     components: {
//       "project-list": () =>
//         import(
//           /* webpackChunkName: "project-list" */ "./vue-components/ProjectList.vue"
//         ),
//     },
//     template: `
//       <div>
//         <div v-if="loading">Loading...</div>
//         <div v-else>
//           <project-list
//             :projects="projects"
//             :categories="categories"
//             :job-types="jobTypes"
//           ></project-list>
//         </div>
//       </div>
//     `,
//   });

//   new Vue({
//     el: "#single-project-container",
//     data() {
//       return {
//         project: [], // Keep this an array to store the project data
//         loading: true,
//       };
//     },
//     mounted() {
//       this.loadProjectData("project", 1);
//     },
//     methods: {
//       loadProjectData(postType, postLimit) {
//         fetch(
//           `/wp-admin/admin-ajax.php?action=map_get_posts&post_type=${postType}&limit=${postLimit}`
//         )
//           .then((response) => response.json())
//           .then((data) => {
//             this.project = data;
//             console.log("This is projects data:", data);
//             this.loading = false;

//             console.log("this.project:", this.project);
//             this.$nextTick(() => {
//               console.log("for each map fired");
//               if (this.project.location_map) {
//                 this.initMap(data.location_map.lat, data.location_map.lng);
//               } else {
//                 console.log("no data location map lat lang");
//               }
//             });
//           });
//       },
//       initMap(lat, lng) {
//         const mapElement = document.getElementById("map");
//         console.log("init map has fired");
//         if (mapElement) {
//           console.log("map element exists");

//           const map = new google.maps.Map(mapElement, {
//             center: { lat: parseFloat(lat), lng: parseFloat(lng) },
//             zoom: 12,
//           });

//           new google.maps.Marker({
//             position: { lat: parseFloat(lat), lng: parseFloat(lng) },
//             map: map,
//           });
//         } else {
//           console.log("map element not loaded");
//         }
//       },
//     },
//     components: {
//       "single-project": () =>
//         import(
//           /* webpackChunkName: "project-single" */ "./vue-components/ProjectSingle.vue"
//         ),
//     },
//     template: `
//       <div>
//         <div v-if="loading">Loading...</div>
//         <div v-else>
//           <h2>TEST</h2>
//           <single-project :project="project"> </single-project>
//         </div>
//       </div>
//     `,
//   });
// });
